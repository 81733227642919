body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.traced-header {
  height: 30px;
  width: 100vw;
  background-image: linear-gradient(90deg, #2C8EB6 33.33%, #F79633 33.33%, #F79633 66.66%, #5CC6D0 66.66%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 0px;
}

.App-logo {
  width: 220px;
  height: 112px;
  margin-top: 40px;
}

.CheckoutBox {
  border: 1px solid #D0D5DD;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  border-radius: 10px;
  max-width: 880px;
  margin: 20px auto;
  position: relative;
  text-align: left;

  @media(max-width: 800px) {
    border: 0;
  }
}

.CheckoutBox h3 {
  background: #F9FAFB;
  padding: 8px 16px;
  font-size: 20px;
  border-radius: 5px;
}